body {
  overflow-y: auto;
  min-height: 100vh;
}

#__next {
  height: 100%;
}

@font-face {
  font-family: 'Roobert';
  src: url('/fonts/Roobert-SemiBold.woff2') format('woff2'),
    url('/fonts/Roobert-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

.fade-enter .fading {
  opacity: 0;
}
.fade-enter-active .fading {
  opacity: 1;
}
.fade-exit .fading {
  opacity: 1;
}
.fade-exit-active .fading {
  opacity: 0;
}
.fade-enter-active .fading,
.fade-exit-active .fading {
  transition: opacity 500ms, transform 500ms;
}

.workflow-icon {
  min-width: 24px !important;
  text-align: right;
  margin-right: 12px;
}

.workflow-icon > svg {
  display: block;
  margin: auto;
}
